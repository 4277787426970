import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	TextField,
	InputAdornment,
	Button,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
// hooks
import useShouldAutofocus from "../../hooks/useShouldAutofocus";
// types
import type { FocusEvent } from "react";

type Props = {
	open: boolean;
	title: string;
	label: string;
	value: number | null;
	min: number | undefined;
	max: number | undefined;
	step: number | undefined;
	unit: string | undefined;
	onChange: (event: null, value: number) => void;
	onCancel: () => void;
};

const NumberPicker = (props: Props) => {
	const { t } = useTranslation();

	const shouldAutofocus = useShouldAutofocus();

	const [value, setValue] = useState(props.value);

	useEffect(() => {
		if (!props.open) {
			setValue(props.value);
		}
	}, [props.open, props.value]);

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		const newValue = Number(event.target.value);
		if (props.min !== undefined && newValue < props.min) {
			setValue(props.min);
		}
		if (props.max !== undefined && newValue > props.max) {
			setValue(props.max);
		}
	};

	return (
		<DrawerDialog
			id="dlg-number-picker"
			title={props.title}
			open={props.open}
			onClose={props.onCancel}
			drawerActions={
				<Button
					className="btn-drawer-action-ok"
					variant="contained"
					disabled={value === null || (props.min !== undefined && props.min > value) || (props.max !== undefined && value > props.max)}
					onClick={() => (props.onChange(null, value as number))}
					sx={{ minWidth: "40%", maxWidth: "320px" }}
				>
					{t("dialog.ok")}
				</Button>
			}
			dialogActions={
				<>
					<Button className="btn-dlg-action-cancel" color="inherit" onClick={props.onCancel}>{t("dialog.cancel")}</Button>
					<Button
						className="btn-dlg-action-ok"
						variant="contained"
						disableElevation={true}
						disabled={value === null || (props.min !== undefined && props.min > value) || (props.max !== undefined && value > props.max)}
						onClick={() => (props.onChange(null, value as number))}
					>
						{t("dialog.ok")}
					</Button>
				</>
			}
		>
			<TextField
				type="number"
				autoFocus={shouldAutofocus}
				label={props.label}
				inputProps={{
					min: props.min,
					max: props.max,
					step: props.step,
					inputMode: "numeric",
					style: { textAlign: "right" },
				}}
				InputProps={{ endAdornment: props.unit ? <InputAdornment position="end">{props.unit}</InputAdornment> : undefined }}
				InputLabelProps={{
					shrink: true,
				}}
				value={value}
				onChange={(event) => (setValue(Number(event.target.value)))}
				onBlur={handleBlur}
				sx={{ width: "192px", margin: "auto" }}
			/>
		</DrawerDialog>
	);
};

NumberPicker.defaultProps = {
	min: undefined,
	max: undefined,
	step: undefined,
	unit: undefined,
};

NumberPicker.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	unit: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default NumberPicker;
