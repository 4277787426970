import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Select, MenuItem, Button, ListItemText, FormControl, InputLabel } from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
// services
import Constants from "../../services/constants";
import i18n from "../../services/i18n";
import { numberFormatter1Digits } from "../../services/l10n";
import { roundToPrecision, convertToF, convertToC, getNumberRange } from "../../services/utils";
// types
import type { TFunction } from "i18next";
import type { WithTranslation } from "react-i18next";
import type { SelectChangeEvent } from "@mui/material/Select";

type Props = WithTranslation & {
	title: string;
	open: boolean;
	value: number;
	min: number;
	max: number;
	step: number;
	unit: string;
	okText: string;
	onChange: (event: null, value: number) => void;
	onCancel: () => void;
};

type State = {
	showDialog: boolean;
	value: number;
	min: number;
	max: number;
};

class TemperaturePicker extends Component<Props, State> {

	#t: TFunction = this.props.t;

	constructor(props: Props) {
		super(props);

		this.state = {
			showDialog: props.open,
			value: TemperaturePicker.getRoundedValueInF(props.value, props.step, props.unit),
			min: TemperaturePicker.getRoundedValueInF(props.min, props.step, props.unit),
			max: TemperaturePicker.getRoundedValueInF(props.max, props.step, props.unit),
		};

		this.closeDialog = this.closeDialog.bind(this);
		this.handleOkClick = this.handleOkClick.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
	}

	override componentDidUpdate(prevProps: Props) {
		if (prevProps.open !== this.props.open) {
			this.setState({
				showDialog: this.props.open,
			});
		}
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: TemperaturePicker.getRoundedValueInF(this.props.value, this.props.step, this.props.unit),
			});
		}
		if (prevProps.min !== this.props.min || prevProps.max !== this.props.max || prevProps.step !== this.props.step || prevProps.unit !== this.props.unit) {
			this.setState({
				min: TemperaturePicker.getRoundedValueInF(this.props.min, this.props.step, this.props.unit),
				max: TemperaturePicker.getRoundedValueInF(this.props.max, this.props.step, this.props.unit),
			});
		}
	}

	static getRoundedValueInF(value: number, step: number, unit: string) {
		if (typeof value !== "number") {
			return value;
		}
		if (unit === Constants.TempUnit.Fahrenheit) {
			return convertToF(value, step);
		}
		return roundToPrecision(value, step);
	}

	closeDialog() {
		this.setState({
			showDialog: false,
			value: TemperaturePicker.getRoundedValueInF(this.props.value, this.props.step, this.props.unit),
		});
		this.props.onCancel();
	}

	handleValueChange(event: SelectChangeEvent<number>) {
		this.setState({
			value: event.target.value as number,
		});
	}

	handleOkClick() {
		this.setState({
			showDialog: false,
		});
		const value = (this.props.unit === Constants.TempUnit.Fahrenheit) ? convertToC(this.state.value, 0.01) : this.state.value;
		this.props.onChange(null, value);
	}

	override render() {
		return (
			<DrawerDialog
				id="dlg-temperature-picker"
				title={this.props.title}
				open={this.state.showDialog}
				onClose={this.closeDialog}
				drawerActions={
					<Button
						className="btn-drawer-action-ok"
						variant="contained"
						onClick={this.handleOkClick}
						sx={{ minWidth: "40%", maxWidth: "320px" }}
					>
						{this.props.okText}
					</Button>
				}
				dialogActions={
					<>
						<Button className="btn-dlg-action-cancel" color="inherit" onClick={this.closeDialog}>{this.#t("dialog.cancel")}</Button>
						<Button
							className="btn-dlg-action-ok"
							variant="contained"
							disableElevation={true}
							onClick={this.handleOkClick}
						>
							{this.props.okText}
						</Button>
					</>
				}
			>
				<FormControl sx={{ width: "50%", margin: "auto" }}>
					<InputLabel shrink={true}>
						{this.#t("general.temperature")}
					</InputLabel>
					<Select
						fullWidth={true}
						value={this.state.value}
						onChange={this.handleValueChange}
					>
						{getNumberRange(this.state.min, this.state.max, this.props.step).map((value) => (
							<MenuItem className="select-temperature" key={value} value={value}>
								<ListItemText primary={`${numberFormatter1Digits(value)}${Constants.Units.Degree}${this.props.unit}`} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DrawerDialog>
		);
	}

}

TemperaturePicker.defaultProps = {
	value: null,
	step: 0.5,
	okText: i18n.t("dialog.ok"),
};

TemperaturePicker.propTypes = {
	title: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.number,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number,
	unit: PropTypes.oneOf([Constants.TempUnit.Celsius, Constants.TempUnit.Fahrenheit]).isRequired,
	okText: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default withTranslation()(TemperaturePicker);
