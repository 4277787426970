import { memo } from "react";
import PropTypes from "prop-types";
import { Slider } from "@mui/material";

type Value = number; // could be also Array<number> for range values

type Props = {
	value: Value;
	onChange: (value: Value) => void;
	onChangeCommitted: (value: Value) => void;
};

const DimSlider = ({value, onChange, onChangeCommitted, ...other}: Props) => (
	<Slider
		min={0}
		max={100}
		step={1}
		value={value}
		onChange={(event, value) => (onChange(value as Value))}
		onChangeCommitted={(event, value) => (onChangeCommitted(value as Value))}
		sx={{ verticalAlign: "middle" }}
		{...other}
	/>
);

DimSlider.defaultProps = {
	onChangeCommitted: () => {},
};

DimSlider.propTypes = {
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onChangeCommitted: PropTypes.func,
};

export default memo(DimSlider);
