import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	Button,
	List,
	ListItemButton,
	ListItemText,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
// services
import Constants from "../../services/constants";
// types
import type { TFunction } from "i18next";
import type { WithTranslation } from "react-i18next";

type VaccumModeValue = typeof Constants.VaccumModes[number]["value"];

type Props = WithTranslation & {
	open: boolean;
	value: number;
	isFullMenuView: boolean;
	onChange: (vaccumMode: VaccumModeValue) => void;
	onCancel: () => void;
};

type State = {
	showDialog: boolean;
	value: number;
};

class VaccumModesPicker extends Component<Props, State> {

	#t: TFunction = this.props.t;

	constructor(props: Props) {
		super(props);

		this.state = {
			showDialog: props.open,
			value: props.value,
		};

		this.closeDialog = this.closeDialog.bind(this);
	}

	override componentDidUpdate(prevProps: Props) {
		if (prevProps.open !== this.props.open) {
			this.setState({
				showDialog: this.props.open,
			});
		}
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	handleModeClick(mode: VaccumModeValue) {
		this.setState({
			showDialog: false,
			value: mode,
		}, () => {
			this.props.onChange(mode);
		});
	}

	closeDialog() {
		this.setState({
			showDialog: false,
		});
		this.props.onCancel();
	}

	override render() {
		const supportedVaccumModes = Constants.VaccumModes.filter((mode) => (mode.isVisibleOnBasicView || this.props.isFullMenuView));

		return (
			<DrawerDialog
				id="dlg-vaccum-modes-picker"
				title={this.#t("clusters.DFF97.modeDialogTitle")}
				open={this.state.showDialog}
				onClose={this.closeDialog}
				drawerActions={null}
				dialogActions={<Button className="btn-dlg-action-cancel" color="inherit" onClick={this.closeDialog}>{this.#t("dialog.cancel")}</Button>}
			>
				<List disablePadding={true}>
					{supportedVaccumModes.map((mode) => (
						<ListItemButton
							className="li-vaccum-mode"
							data-vaccum-mode={mode.value}
							key={mode.value}
							sx={(mode.value === this.state.value) ? { color: "primary.main" } : {}}
							onClick={this.handleModeClick.bind(this, mode.value)}
						>
							<ListItemText primary={this.#t(`clusters.DFF97.vaccumModes.${mode.l10n}`)} />
						</ListItemButton>
					))}
				</List>
			</DrawerDialog>
		);
	}

}

VaccumModesPicker.propTypes = {
	open: PropTypes.bool.isRequired,
	isFullMenuView: PropTypes.bool.isRequired,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default withTranslation()(VaccumModesPicker);
